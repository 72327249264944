import Navbar from "./components/header/Navbar";
import Footer from "./components/footer/Footer";
import { useRoutes } from "react-router-dom";
import { Suspense, lazy } from "react";
import { RouteEnum } from "common/constant";
import { CircularProgress } from "@mui/material";
import { useLocation } from "react-router-dom";
import useScrollToTop from "hooks/useScrollToTop";

const Homepage = lazy(() => import("./pages/Homepage"));
const AboutPage = lazy(() => import("./pages/AboutPage"));
const DriverPage = lazy(() => import("./pages/DriverPage"));
const PrivacyPage = lazy(() => import("./pages/PrivacyPage"));
const Copyright = lazy(() => import("./pages/Copyright"));
const Support = lazy(() => import("./pages/Support"));
const DeleteData = lazy(() => import("./pages/delete-data"));

function App() {
  const routes = useRoutes(ROUTES);

  const location = useLocation();

  useScrollToTop(location);

  return (
    <Suspense
      fallback={
        <div className="text-center mt-5 text-DarkBlue">
          <CircularProgress />
        </div>
      }
    >
      <div className="max-w-full overflow-hidden">
        <Navbar />
        {routes}
        <Footer />
      </div>
    </Suspense>
  );
}

export default App;

const ROUTES = [
  {
    path: "*",
    element: <Homepage />,
  },
  {
    path: RouteEnum.ABOUT,
    element: <AboutPage />,
  },
  {
    path: RouteEnum.DRIVER,
    element: <DriverPage />,
  },
  {
    path: RouteEnum.PRIVACY,
    element: <PrivacyPage />,
  },
  {
    path: RouteEnum.COPYRIGHT,
    element: <Copyright />,
  },
  {
    path: RouteEnum.SUPPORT,
    element: <Support />,
  },
  {
    path: RouteEnum.DELETE_DATA,
    element: <DeleteData />,
  },
];
