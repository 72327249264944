import React, { useState } from "react";
import logo from "../../assets/Frame 2764.png";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";
import { RouteEnum } from "common/constant";
import Sidebar from "./Sidebar";

function Navbar() {
  const [showToggle, setShowToggle] = useState(false);
  return (
    <>
      <nav className="bg-Black text-White">
        <div className="p-3 w-full mx-auto flex justify-between items-center md:w-11/12">
          <Link to={RouteEnum.HOME}>
            <img src={logo} alt="logo" width={200} />
          </Link>

          <div className="hidden items-center gap-8 justify-between md:flex">
            <Link to={RouteEnum.ABOUT}>
              <h4>About Us</h4>
            </Link>
            <Link to={RouteEnum.DRIVER}>
              <button className="py-3 px-5 border border-PrimaryDark rounded-lg hover:bg-White hover:text-Black">
                Become a driver
              </button>
            </Link>
          </div>
          <div className="block md:hidden">
            <MenuIcon
              className=" hover:text-PrimaryLight"
              fontSize="large"
              onClick={() => setShowToggle(true)}
            />
          </div>
        </div>
      </nav>
      {showToggle && (
        <Sidebar setShowToggle={setShowToggle} showToggle={showToggle} />
      )}
    </>
  );
}

export default Navbar;
