import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom';
import { RouteEnum } from 'common/constant';

const Sidebar = ({ setShowToggle }) => {
  return (
    <>
      <aside className='animation fixed z-20 text-center top-0 right-0 bg-White h-full w-2/3 p-5 ease-in-out duration-300'>
        <CloseIcon
          fontSize='large'
          onClick={() => setShowToggle(false)}
          className='float-right hover:text-Primary'
        />
        <div className='flex flex-col space-y-5 mt-24'>
          <Link to={RouteEnum.ABOUT} onClick={() => setShowToggle(false)}>
            <h4>About Us</h4>
          </Link>
          <Link to={RouteEnum.DRIVER} onClick={() => setShowToggle(false)}>
            <button className='py-3 px-5 border border-PrimaryDark rounded-lg hover:bg-White hover:text-Black'>
              Become a driver
            </button>
          </Link>
        </div>
      </aside>
      <div
        onClick={() => setShowToggle(false)}
        className='fixed top-0 z-10 bg-black w-full h-full opacity-60'
      />
    </>
  );
};

export default Sidebar;
